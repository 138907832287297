<template>
  <div id="h5">
    <HomeHeader :height="headerHeight" />
    <!-- 站位盒子 -->
    <div class="station-box"></div>
    <PromoCards />
  </div>
</template>

<script>
import HomeHeader from "./HomeHeader";
import PromoCards from "./PromoCards";
import elementHeight from "@/mixins/h5/elementHeight";
export default {
  name: "HomeH5",

  mixins: [elementHeight],
  components: {
    HomeHeader,
    PromoCards,
  },
};
</script>

<style lang="less" scoped>
#h5 {
  width: 100%;
  height: auto;
  background: url("~@/assets/images/h5/home/home-bg.png") no-repeat;
  background-size: cover;
  .station-box {
    height: 560px;
    width: 100%;
  }
}
</style>
